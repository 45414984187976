import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";


const apiNamespace = "report";

const getBranchSubscriptionLogic = logic(apiNamespace, { actionName: ActionTypes.GET_BRANCH_SUBSCRIPTION });
const getTransactionsClientsLogic = logic(apiNamespace, { actionName: ActionTypes.GET_TRANSACTIONS_CLIENTS });
const getAllBranchesLogic = logic(apiNamespace, {actionName: ActionTypes.GET_ALL_BRANCHES});
const getTransactionsPerBranchLogic = logic(apiNamespace, {actionName: ActionTypes.GET_TRANSACTIONS_PER_BRANCH});
const getConsumedCupsLogic = logic(apiNamespace, {actionName: ActionTypes.GET_CONSUMED_CUPS});
const getTotalConsumedCupsLogic = logic(apiNamespace, {actionName: ActionTypes.GET_TOTAL_CONSUMED_CUPS});
const getConsumedCupsPaginationLogic = logic(apiNamespace, {actionName: ActionTypes.GET_CONSUMED_CUPS_PAGINATION});
const getSubscriptionUsersLogic = logic(apiNamespace, {actionName: ActionTypes.GET_SUBSCRIPTION_USERS});
const getTotalSubscriptionUsersLogic = logic(apiNamespace, {actionName: ActionTypes.GET_TOTAL_SUBSCRIPTION_USERS});
const getConsumedCupsPerBranchLogic = logic(apiNamespace, {actionName: ActionTypes.GET_CONSUMED_CUPS_PER_BRANCH});
const getTotalConsumedCupsPerBranchLogic = logic(apiNamespace, {actionName: ActionTypes.GET_TOTAL_CONSUMED_CUPS_PER_BRANCH});
const getUserRedeemptionLogic = logic(apiNamespace, {actionName: ActionTypes.GET_USER_REDEEMPTION});
const getTotalUserRedeemptionLogic = logic(apiNamespace, {actionName: ActionTypes.GET_TOTAL_USER_REDEEMPTION});
const getClientCupTransactionsLogic = logic(apiNamespace, {actionName: ActionTypes.GET_CLIENT_CUP_TRANSACTIONS});

export default [getBranchSubscriptionLogic,getTransactionsClientsLogic,
    getAllBranchesLogic,
    getTransactionsPerBranchLogic,
    getConsumedCupsLogic,
    getConsumedCupsPerBranchLogic,
    getUserRedeemptionLogic,
    getSubscriptionUsersLogic,
    getConsumedCupsPaginationLogic,
    getTotalSubscriptionUsersLogic,
    getTotalUserRedeemptionLogic,
    getTotalConsumedCupsPerBranchLogic,
    getTotalConsumedCupsLogic,
    getClientCupTransactionsLogic,
  

];

