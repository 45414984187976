import apisauce from "apisauce";
import config from "../config";

import authApi from "./auth";
import usersApi from "./userManagement";
import brandsApi from "./brand";
import outletsApi from './outlet';
import questionsApi from "./question";
import homeApi from "./home";
import paymentApi from "./payment";
import reportApi from "./report";
import branchApi from "./branch";
import settingApi from "./setting";






const apiURI = config.useLocalApi ? config.devApiRoot : config.apiRoot;
const create = (baseURL = apiURI) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache"
    },
    timeout: 1200000 //30 sec
  });
  //add access  on each request

  api.addAsyncResponseTransform(response => async () => {
    if (!response.ok) {
      if (response.status !== 401) {
        const customeError = {
          errors: response.data.errors || [{ errorMessage: response.data.errorMessage }],
          // title: response.data.title,
          errorMessage: response.data.errorMessage,
          // details: response.data.details,
          // data: response.data.result,
          status: response.status,
          code: response.data.errorCode
        }
        response.data = customeError;
      }
    }
  });

  api.addAsyncRequestTransform(request => async () => {
    const token = localStorage.getItem("jwtToken");
    const culture = localStorage.getItem("culture");
    const cultureCode = localStorage.getItem("cultureCode");
    const userIp = localStorage.getItem("userIp");

    request.headers["culture"] = culture || "en";
    request.headers["cultureCode"] = cultureCode || "en-US";
    request.headers["userIp"] = userIp || "";

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete request.headers["Authorization"];
    }
  });

  const auth = authApi(api);
  const users = usersApi(api);
  const brands = brandsApi(api);
  const outlets = outletsApi(api);
  const questions = questionsApi(api);
  const home = homeApi(api);
  const payment = paymentApi(api);
  const report = reportApi(api);
  const branch = branchApi(api);
  const setting = settingApi(api);


  // const orders = ordersApi(api);
  // const transactions = transactionsApi(api);
  return {
    setHeader: api.setHeader,
    setHeaders: api.setHeaders,
    ...auth,
    ...users,
    ...brands,
    ...outlets,
    ...questions,
    ...home,
    ...payment,
    ...report,
    ...branch,
    ...setting
  };
};

export default { create };
