import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";




const initialState = {
  ...defaultState("subscriptionUsers", { metadata: undefined, selectedUser: undefined}),
  ...defaultState("users", { metadata: undefined, selectedUser: undefined}),

};



 export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
     action: "getBranchSubscription", 
     stateName: "branchInfo",
     async: true, 
     isPaginated: false
  }),
 ...bindReducers(ActionTypes, {
    action: "getSubscriptionUsers", 
    stateName: "subscriptionUsers",
   async: true, 
    isPaginated: true
 }),
 ...bindReducers(ActionTypes, {
  action: "getTotalSubscriptionUsers", 
  stateName: "totalSubscriptionUsers",
  async: true, 
  isPaginated: true
}),
   ...bindReducers(ActionTypes, {
     action: "getTransactionsClients", 
     stateName: "transactionClientInfo",
     async: true, 
     isPaginated: false
   }),
    ...bindReducers(ActionTypes, {
     action: "getTransactionsClients", 
     stateName: "transactionClientInfo",
     async: true, 
     isPaginated: false
    }),
    ...bindReducers(ActionTypes, {
    action: "getAllBranches",
    stateName: "Branches",
    async: true,
    isPaginated: false,
  }),
    ...bindReducers(ActionTypes, {
    action: "getTransactionsPerBranch",
    stateName: "transactionsPerBranch",
    async: true,
    isPaginated: false,
  }),
    ...bindReducers(ActionTypes, {
    action: "getConsumedCups",
    stateName: "consumedCups",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTotalConsumedCups",
    stateName: "totalConsumedCups",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "getConsumedCupsPagination",
    stateName: "consumedCupsPagination",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getConsumedCupsPerBranch",
    stateName: "consumedCupsPerBranch",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTotalConsumedCupsPerBranch",
    stateName: "totalConsumedCupsPerBranch",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getUserRedeemption",
    stateName: "userRedeemption",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getTotalUserRedeemption",
    stateName: "totalUserRedeemption",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getClientCupTransactions",
    stateName: "clientCupTransactions",
    async: true,
    isPaginated: true,
  }),
});
