import { TextField } from '@material-ui/core'
import React, {  useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Header, Footer } from 'src/app/components/Layout'
import paymentActions from '../../store/payment/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { ErrorMessage, LoadingButton } from 'src/app/components'
import { ArrowForwardIos } from '@material-ui/icons'



function UnsubscribeForClient({ unSubscribeForClient,unSubscribedClientLoading,
    unSubscribedClient_errors,resetPayment,unSubscribedClient}) {
    const validationSchema = Yup.object().shape({

        userName: Yup.string()
        .required("userName is  Required "),
   

})

const formik = useFormik({
    initialValues: {
        userName: '',
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
        unSubscribeForClient(values);
    }
})

useEffect(() => {

    return () => {
        resetPayment();
    }
}, [])

return (
<>
    {/* <Header />   */}
        <div className="container text-center mt-4 page-content">
            <h2 className="font-weight-bold mb-4 main-color mb-4 ve-font">Unsubscribe For Client</h2>
            <form className="alignment-center" onSubmit={formik.handleSubmit}>
                 <TextField
                        className="w-4 mb-3"
                        id="userName"
                        name="userName"
                        label="userName"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        error={!!formik.errors.userName && !!formik.errors.userName}
                        helperText={formik.touched.userName && formik.errors.userName}
                        placeholder="Type here"
                        onBlur={() => formik.setFieldTouched("userName", true)}
                    />
                 
                    <LoadingButton loading={unSubscribedClientLoading} type="submit" size="large" className='mt-4 secondary-bg  mb-3 w-2 b-25 position-relative ge-font' variant="primary">
            Submit
                  <ArrowForwardIos className='ml-2 position-absolute right custom-i' />
                </LoadingButton>
                

            </form>
            <ErrorMessage errors={unSubscribedClient_errors} />
           {unSubscribedClient && <ErrorMessage message={"client unsubscribed successfully"} color='text-success'/>}


        </div>
   <Footer/>
</>
)
}
const mapStateToProps = ({ payment: {  unSubscribedClient_errors, unSubscribedClientLoading ,unSubscribedClient} }) => ({


    unSubscribedClient_errors,
    unSubscribedClientLoading,
    unSubscribedClient,

})

const mapDispatchToProps = (dispatch) =>  bindActionCreators({...paymentActions},dispatch)
export default connect(mapStateToProps,mapDispatchToProps)(UnsubscribeForClient)

