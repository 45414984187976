import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "src/app/utils/genState";
import createReducer from "src/app/utils/reduxsauce/createReducer";

const initialState = {};
const disableClientCupRedemptionSuccess = (state, draft, payload) => {
  draft.disabledClientFromCupRedemption_errors = [];
};
const disableClientCupRedemptionFailure = (state, draft, payload) => {
  draft.disabledClientFromCupRedemption = undefined;
};
const enableClientCupRedemptionSuccess = (state, draft, payload) => {
  draft.enabledClientFromCupRedemption_errors = [];
};
const enableClientCupRedemptionFailure = (state, draft, payload) => {
  draft.enabledClientFromCupRedemption = undefined;
};
const unSubscribeForClientSuccess = (state, draft, payload) => {
  draft.unSubscribedClient_errors = [];
};
const unSubscribeForClientFailure = (state, draft, payload) => {
  draft.unSubscribedClient = undefined;
};
const redeemCupSuccess = (state, draft, payload) => {
  draft.cupRedeem_errors = [];
};
const resetPayment = (state, draft, payload) => {
  draft.clientUnSubscribe_errors = [];
  draft.cupRedeem_errors = [];
  draft.cupRedeem = [];
  draft.clientUnSubscribe = undefined;
  draft.unSubscribedClient_errors = [];
  draft.unSubscribedClientLoading = false;
  draft.disabledClientFromCupRedemption_errors = [];
  draft.disabledClientFromCupRedemptionLoading = false;
};

export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "redeemCup",
    stateName: "cupRedeem",
    async: true,
    isPaginated: false,
    successCb: redeemCupSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "subscribeClient",
    stateName: "clientSubscribe",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "unSubscribeClient",
    stateName: "clientUnSubscribe",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "unSubscribeForClient",
    stateName: "unSubscribedClient",
    async: true,
    isPaginated: false,
    successCb: unSubscribeForClientSuccess,
    failCb: unSubscribeForClientFailure,
  }),
  ...bindReducers(ActionTypes, {
    action: "disableClientCupRedemption",
    stateName: "disabledClientFromCupRedemption",
    async: true,
    isPaginated: false,
    successCb: disableClientCupRedemptionSuccess,
    failCb: disableClientCupRedemptionFailure,
  }),
  ...bindReducers(ActionTypes, {
    action: "enableClientCupRedemption",
    stateName: "enabledClientFromCupRedemption",
    async: true,
    isPaginated: false,
    successCb: enableClientCupRedemptionSuccess,
    failCb: enableClientCupRedemptionFailure,
  }),
  ...bindReducers(ActionTypes, {
    action: "paymentProcessed",
    stateName: "paymentProcessed",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "valuPaymentProcessed",
    stateName: "valuPaymentProcessed",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "resetPayment",
    stateName: "resetPayment",
    async: false,
    isPaginated: false,
    successCb: resetPayment,
  }),
});
