import React from 'react'

function ErrorMessage({ message, errors = [] ,color="text-danger"}) {
    if (message && message !== "") {
        return (
            <div className="mb-10 alert alert-custom alert-light-danger ">
                <div 
               // className="alert-text font-weight-bold text-danger"
                className={`alert-text font-weight-bold ${color}`}
                >{message}</div>
            </div>
        )
    } else if (errors && errors.length > 0) {
       return <ul className="mb-10 alert alert-custom alert-light-danger ">
           {errors.map((e)=>{
               return <li className="alert-text font-weight-bold text-danger">{e?.errorMessage||e}</li>
           })}
            
        </ul>
    }
    else
        return <></>;
}

export default ErrorMessage
