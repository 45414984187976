import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    redeemCup: { args: ["payload"], meta: { async: true } },
    subscribeClient: { args: ["payload"], meta: { async: true } },
    unSubscribeClient: { args: ["payload"], meta: { async: true } },
    unSubscribeForClient: { args: ["payload"], meta: { async: true } },
    disableClientCupRedemption: { args: ["payload"], meta: { async: true } },
    enableClientCupRedemption: { args: ["payload"], meta: { async: true } },
    paymentProcessed: { args: [], meta: { async: true } },
    valuPaymentProcessed: { args: [], meta: { async: true } },

    resetPayment: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/payment/",
  }
);
export const ActionTypes = Types;
export default Creators;
