import { createReducer } from "reduxsauce";
import { produce } from "immer";
// import isEmpty from "ramda/es/isEmpty";
import jwtDecoder from "jwt-decode";
import storage from "redux-persist/lib/storage/session";
import { ActionTypes } from "./actions";
import { createBlacklistTransformation } from "src/app/utils/redux-persist-transformers";
import { persistReducer } from "redux-persist";
import { defaultState, bindReducers } from "src/app/utils/genState";
const initialState = {
  user: null,
  isLoggedIn: false,
  token: null,
  loginError: undefined,
  registerLoading: false,
  registerError: undefined,
  notifications: [],
  forgetPasswordMessage: undefined,
};

const login = state => {
  return produce(state, draft => {
    draft.loginLoading = true;
  });
};
const register = state => {
  return produce(state, draft => {
    draft.registerLoading = true;

  });

};

const registerSuccess = (state, { payload }) => {
  return produce(state, draft => {

    draft.registerLoading = false;

  });
};
const registerFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.registerLoading = false;
    draft.registerError = payload.errors[0]?.errorMessage;
  });
};
const loginSuccess = (state, { payload }) => {
  return produce(state, draft => {

    draft.loginLoading = false;
    let user = jwtDecoder(payload.data.token);
    draft.user = user
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('jwtToken', payload.data.token)
    localStorage.setItem("refreshToken", payload.data.refreshToken);


  });
};
const loginFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.loginLoading = false;
    draft.loginError = payload.errorMessage;

  });
};
const forgetPassword = state => {
  return produce(state, draft => {
    draft.forgetPasswordLoading = true;


  });
};
const forgetPasswordSuccess = (state, { payload }) => {
  const {data}=payload;
  return produce(state, draft => {
    draft.forgetPasswordLoading = false;
    draft.forgetPasswordError=undefined;
    draft.forgetPasswordMessage = data;


  });
};
const forgetPasswordFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.forgetPasswordLoading = false;
    draft.forgetPasswordMessage = payload.errorMessage;
    draft.forgetPasswordError=payload?.errors;
  });
};
const resetPassword = state => {
  return produce(state, draft => {
    draft.resetPasswordLoading = true;

  });

};
const resetPasswordSuccess = (state, { payload }) => {
  return produce(state, draft => {

    draft.resetPasswordLoading = false;

  });
};
const resetPasswordFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.resetPasswordLoading = false;
    draft.resetPasswordError = payload.errors;

  });
};
const resetPasswordForClient = state => {
  return produce(state, draft => {
    draft.resetPasswordForClientLoading = true;

  });

};
const resetPasswordForClientSuccess = (state, { payload }) => {
  return produce(state, draft => {

    draft.resetPasswordForClientLoading = false;

  });
};
const resetPasswordForClientFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.resetPasswordForClientLoading = false;
    draft.resetPasswordForClientError = payload.errors;

  });
};
const refreshToken = state => {
  return produce(state, draft => {
    draft.refreshTokenLoading = true;

  });

};
const refreshTokenSuccess = (state, { payload })=> {
  return produce(state, draft => {
    localStorage.setItem("jwtToken", payload.data.token);
    localStorage.setItem("refreshToken", payload.data.refreshToken);
    draft.resetPasswordLoading = false;

  });

};
const refreshTokenFail = (state, { payload }) => {
  return produce(state, draft => {
    localStorage.removeItem("user");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refreshToken");
    draft.user = undefined;
    draft.refreshTokenLoading = false;
    draft.refreshTokenError = payload.errors;

  });
};

const logout = (state, { payload }) => {
  return produce(state, draft => {
    localStorage.removeItem('user')
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('refreshToken')
    draft.user = undefined;
  });
};

const getNotifications = state => {
  return produce(state, draft => {
    draft.loginLoading = true;
  });
};
const getNotificationsSuccess = (state, { payload }) => {
  return produce(state, draft => {
    draft.notifications = payload.data
    draft.loginLoading = false;

  });
};
const getNotificationsFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.loginLoading = false;
    draft.loginError = payload.message
  });
};
const addNotification = (state, { notify }) => {
  return produce(state, draft => {
    draft.loginLoading = false;
    draft.notifications = [notify, ...state.notifications]
  });
};
const readNotification = (state, { notify }) => {
  return produce(state, draft => {
  });
};
const readNotificationSuccess = (state, { payload }) => {
  return produce(state, draft => {
    draft.notifications.forEach(a => {
      if (a.notifyId === payload.data.notficiationId)
        a.read = true;
    });
  });
};
const readNotificationFailed = (state, { notify }) => {
  return produce(state, draft => {
  });
};
const changePassword = state => {
  return produce(state, draft => {
    draft.changePasswordLoading = true;

  });
};
const changePasswordSuccess = (state, { payload }) => {
  return produce(state, draft => {

    draft.changePasswordLoading = false;

  });
};
const changePasswordFail = (state, { payload }) => {
  return produce(state, draft => {
    draft.changePasswordLoading = false;
    draft.changePasswordError = payload.errors;

  });
};

const reset = (state, { }) => {
  return produce(state, draft => {
    draft.loginError = undefined;
    draft.registerError = undefined;
    draft.register_errors = undefined;
    draft.forgetPasswordMessage = undefined;
    draft.forgetPasswordError = undefined;
    draft.resetPasswordError = undefined;
    draft.changePasswordMessage = undefined;
    draft.changePasswordError = undefined;
    draft.resetPasswordForClientError=undefined;
  });
}

export const _authReducer = createReducer(initialState, {
  [ActionTypes.LOGIN]: login,
  [ActionTypes.LOGIN_SUCCESS]: loginSuccess,
  [ActionTypes.LOGIN_FAIL]: loginFail,
  [ActionTypes.CHANGE_PASSWORD]: changePassword,
  [ActionTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [ActionTypes.CHANGE_PASSWORD_FAIL]: changePasswordFail,
  [ActionTypes.FORGET_PASSWORD]: forgetPassword,
  [ActionTypes.FORGET_PASSWORD_SUCCESS]: forgetPasswordSuccess,
  [ActionTypes.FORGET_PASSWORD_FAIL]: forgetPasswordFail,
  [ActionTypes.RESET_PASSWORD]: resetPassword,
  [ActionTypes.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [ActionTypes.RESET_PASSWORD_FAIL]: resetPasswordFail,
  [ActionTypes.RESET_PASSWORD_FOR_CLIENT]: resetPasswordForClient,
  [ActionTypes.RESET_PASSWORD_FOR_CLIENT_SUCCESS]: resetPasswordForClientSuccess,
  [ActionTypes.RESET_PASSWORD_FOR_CLIENT_FAIL]: resetPasswordForClientFail,
  [ActionTypes.REFRESH_TOKEN]: refreshToken,
  [ActionTypes.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [ActionTypes.REFRESH_TOKEN_FAIL]: refreshTokenFail,
  [ActionTypes.LOGOUT]: logout,
  [ActionTypes.GET_NOTIFICATIONS]: getNotifications,
  [ActionTypes.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [ActionTypes.GET_NOTIFICATIONS_FAIL]: getNotificationsFail,
  [ActionTypes.ADD_NOTIFICATION]: addNotification,
  [ActionTypes.READ_NOTIFICATION]: readNotification,
  [ActionTypes.READ_NOTIFICATION_SUCCESS]: readNotificationSuccess,
  [ActionTypes.READ_NOTIFICATION_FAIL]: readNotificationFailed,
  [ActionTypes.RESET]: reset,
  ...bindReducers(ActionTypes, {
    action: "register",
    stateName: "register",
    async: true,
    isPaginated: false,
  }),
});

const authlacklistedProps = [
  "loginError",
];

const authPersistConfig = {
  key: "reservations",
  storage: storage,
  blacklist: authlacklistedProps.filter(a => !a.includes(".")),
};
export const authReducer = persistReducer(authPersistConfig, _authReducer);