export default function(/**@type {ApisauceInstance} */ api) {
    const login = loginRequest => api.post("auth/login", loginRequest);
    const register = registerRequest => api.post("auth/register", registerRequest);
    const logout = accessToken => api.post("auth/logout", { accessToken });
    const getNotifications = paging => api.get("user/getnotifications",paging);  
    const readNotification = (id) => api.get("user/readNotify/" + id);  
    const forgetPassword = (email) => api.post("auth/forget-password",email);  
    const resetPassword = resetPasswordRequest => api.post("auth/reset-password",resetPasswordRequest);  
    const resetPasswordForClient = resetPasswordRequest => api.post("auth/reset-password-for-client",resetPasswordRequest);  
    const changePassword = resetPasswordRequest => api.post("auth/change-password",resetPasswordRequest);  
    const refreshToken = (payload) => api.post("auth/refresh-token/", payload);

  
    return {
      auth: {
        login,
        register,
        logout,
        getNotifications,
        readNotification,
        forgetPassword,
        resetPassword,
        resetPasswordForClient,
        changePassword,
        refreshToken,
      }
    };
  }