import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getBranchSubscription: { args: [], meta: { async: true } },
    getTransactionsClients: { args: ["payload"], meta: { async: true } },
    getAllBranches: { args: [], meta: { async: true } },
    getTransactionsPerBranch: { args: ["payload"], meta: { async: true } },
    getConsumedCups: { args: ["payload"], meta: { async: true } },
    getTotalConsumedCups: { args: ["payload"], meta: { async: true } },
    getConsumedCupsPagination: { args: ["payload"], meta: { async: true } },
    getSubscriptionUsers: { args: ["payload"], meta: { async: true } },
    getTotalSubscriptionUsers: { args: ["payload"], meta: { async: true } },
    getConsumedCupsPerBranch: { args: ["payload"], meta: { async: true } },
    getTotalConsumedCupsPerBranch: { args: ["payload"], meta: { async: true } },
    getUserRedeemption: { args: ["payload"], meta: { async: true } },
    getTotalUserRedeemption: { args: ["payload"], meta: { async: true } },
    getClientCupTransactions: { args: ["payload"], meta: { async: true } },


    

    

  },
  {
    prefix: "@app/report/"
  }
);
export const ActionTypes = Types;
export default Creators;