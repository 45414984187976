import React from 'react';
import ReactExport from "react-export-excel";

export const Column = ({ ...props }) => {
    const { ExcelColumn } = ReactExport.ExcelFile;
    return (<ExcelColumn {...props} />)
}
function Excel({ name, data, children }) {
    const { ExcelFile } = ReactExport;
    const { ExcelSheet, ExcelColumn } = ExcelFile;
    const firstRow = data[0];
    return (
        <ExcelFile filename={name} hideElement={true} element={<button ></button>}>

            <ExcelSheet data={data} name={name}>
                {children ?
                    children:
                   Object.keys( firstRow).map((k,i)=>{
return <Column label={k} value={k} key={i}/>

                   })
                }
            </ExcelSheet>

        </ExcelFile>
    )
}

export default Excel
