import React from 'react'
import { useSelector } from 'react-redux';

export const AuthContext=React.createContext();
function UserProvider({children,...props}) {
    const { isAuthorized, user } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            user: auth.user
        })
    );


    return (
        <AuthContext.Provider
        value={{
            ...props,
            isAuthorized: JSON.parse(localStorage.getItem("user"))!=null,
            user:JSON.parse(localStorage.getItem("user"))
          }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default UserProvider
