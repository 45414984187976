import React, { useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import BasePage from "./BasePage";
import Layout from "./components/Layout/Layout";
import AuthPage from "./modules/Auth";
import { AuthContext } from "./containers/Providers/UserProvider";
import { useEffect } from "react";

export function Routes() {
    
    const { isAuthorized, user } = useContext(AuthContext);
    useEffect(()=>console.log("isAuthorized r",isAuthorized),[isAuthorized])

    return (
        <Switch>
            {!isAuthorized ? (
                <Route>
                    <AuthPage />
                </Route>

            ) : (
                    <Redirect from="/login" to="/" />
                )
            }
            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
}
