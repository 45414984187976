import logic from "src/app/utils/genLogic";
import { ActionTypes } from "./actions";
import HomeActionTypes from "../home/actions";
import { push } from "connected-react-router";
import UnsubscribeForClient from "src/app/modules/Admin/UnsubscribeForClient";

const apiNamespace = "payment";

const redeemCupLogic = logic(apiNamespace, {
  actionName: ActionTypes.REDEEM_CUP,
  successCb: (dispatch, payload) => {
    // dispatch(push('/'))
    dispatch(
      HomeActionTypes.getCashierHome({ clientCode: payload.clientCode })
    );
  },
});

const subscribeClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.SUBSCRIBE_CLIENT,
  successCb: (dispatch, payload) => {
    dispatch(
      HomeActionTypes.getCashierHome({ clientCode: payload.clientCode })
    );
  },
});
const unSubscribeClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.UN_SUBSCRIBE_CLIENT,
  successCb: (dispatch, payload) => {
    // dispatch(push('/'))
    // dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
  },
});
const unSubscribeForClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.UN_SUBSCRIBE_FOR_CLIENT,
  successCb: (dispatch, payload) => {
    // dispatch(push('/'))
    // dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
  },
});
const disableClientCupRedemptionLogic = logic(apiNamespace, {
  actionName: ActionTypes.DISABLE_CLIENT_CUP_REDEMPTION,
  successCb: (dispatch, payload) => {
    // dispatch(push('/'))
    // dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
  },
});
const enableClientCupRedemptionLogic = logic(apiNamespace, {
  actionName: ActionTypes.ENABLE_CLIENT_CUP_REDEMPTION,
  successCb: (dispatch, payload) => {
    // dispatch(push('/'))
    // dispatch(HomeActionTypes.getCashierHome({clientCode:payload.clientCode}))
  },
});
const paymentProcessedLogic = logic(apiNamespace, {
  actionName: ActionTypes.PAYMENT_PROCESSED,
  successCb: (dispatch, payload) => {
    window.location.href = payload.url;
  },
});
const valuPaymentProcessedLogic = logic(apiNamespace, {
  actionName: ActionTypes.VALU_PAYMENT_PROCESSED,
  successCb: (dispatch, payload) => {
    window.location.href = payload.url;
  },
});

export default [
  redeemCupLogic,
  subscribeClientLogic,
  unSubscribeClientLogic,
  unSubscribeForClientLogic,
  disableClientCupRedemptionLogic,
  paymentProcessedLogic,
  enableClientCupRedemptionLogic,
  valuPaymentProcessedLogic,
];
