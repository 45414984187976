import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    login: { args: ["loginRequest"], meta: { async: true } },
    register: { args: ["registerRequest"], meta: { async: true } },
    getNotifications: { args: ["pagin"], meta: { async: true } },
    addNotification: { args: ["notify"] },
    logout: { args: ["token"] },
    setAuthToken: { args: ["token"], meta: { async: false } },
    readNotification:{ args: ["payload"], meta: { async: true } },
    forgetPassword:{ args: ["payload"], meta: { async: true } },
    changePassword:{ args: ["payload"], meta: { async: true } },
    reset:{ args: [], meta: { async: false } },
    resetPassword:{ args: ["resetPasswordRequest"], meta: { async: true } },
    resetPasswordForClient:{ args: ["resetPasswordRequest"], meta: { async: true } },
    refreshToken: { args: ["payload"], meta: { async: true } },
  },
  {
    prefix: "@app/auth/"
  }
);
export const ActionTypes = Types;
export default Creators;
export const AuthActions=Creators;