import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router'
import { toAbsoluteUrl } from 'src/app/utils/AssetHelper'
function GameHeader({children,...props}) {
    const history=useHistory();
    return (
        <>
            <div className="header clearfix">
                <div className="float-left "
                 align='center'
                style={{
                          
                    }}
                >

                    <img
                        style={{
                            width: '80%', 
                            marginTop: '40px',
                        }}
                        src={toAbsoluteUrl('/skip.png')} className="img-fluid custom-img" onClick={() => history.push("/")} />



                
                </div>
                <div className="right-info float-right text-right">
                    <img src={toAbsoluteUrl('/s_logo.png')} className="img-fluid custom-img" />
                </div>
            </div>
            {children}
       
        </>
 
    )
}

export default GameHeader

