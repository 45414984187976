import React, { useState } from 'react';
import * as moment from 'moment';
function TimeCounter({ timer = "01:02:59" }) {
    const [counter, setCounter] = useState(timer)
    const downloadTimer = setTimeout(()=>{
        const time=moment(counter,"HH:mm:ss");
        if(time.format("HH:mm:ss")!=="00:00:00"){
          setCounter(moment(counter,"HH:mm:ss").add(-1,"second").format("HH:mm:ss"))  
        }
        
      }, 1000);
    return (
        <>
            {counter}
        </>
    )
}

export default TimeCounter
