import React from 'react'
import Footer from './Footer'
import Header from './Header'

function Layout({children,...props }) {
    return (
        <>
            <Header />
            <div className="page-content">
                <div className="container">
                    {children}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Layout
