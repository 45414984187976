import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/app/utils/AssetHelper'
import authActions from '../../store/auth/actions'
import paymentActions from '../../store/payment/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Dropdown } from 'react-bootstrap'
import { AuthContext } from 'src/app/containers/Providers/UserProvider';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';


function Header({ subLogin, logout ,unSubscribeClient}) {
    const { isAuthorized, user } = useContext(AuthContext)
    const history = useHistory();
    const dispatch=useDispatch();

    const logoutHandler = () => {
        logout();
        history.push('/auth/login');
    }
    useEffect(()=>console.log("isAuthorized",isAuthorized),[isAuthorized])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">

                <Link to={isAuthorized ? "/" : "/auth"} className="navbar-brand left-logo" >
                    <img src={toAbsoluteUrl('/logo3.png')} className="img-fluid header-logo" alt="" />
                </Link>
                

                <div className="right-info float-right">


                    {
                        isAuthorized && user?.role === "Cashier" &&
                        <>
                            <Link to={isAuthorized ? "/" : "/auth"} className="add-customer font-weight-bold main-color mr-1 text-right re-font" >+ ADD NEW CUSTOMER</Link>
                            <Link to={isAuthorized ? "/" : "/auth"} className="add-customer-mobile font-weight-bold main-color mr-3 text-right" >+ CUSTOMER</Link>
                            <button className="btn btn-primary btn-link font-weight-bold mr-re" onClick={logoutHandler} >Log out</button>
                        </>
                    }


                    {user?.role !== "Cashier" && user?.role !== "Admin"&& user?.role !== "SuperAdmin" &&
                        <Link to={isAuthorized ? "/" : "/auth"} className="font-weight-bold main-color mr-3 text-right ge-font" >Home</Link>
                    }
                    {subLogin}
                    {isAuthorized && user?.role === "Client" &&

                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu
                            style={{
                                backgroundColor:"#fffcf1"

                            }}
                            >
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/transactions')}>My Transactions</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms &amp; Conditions</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/unsubscribe')}>unsubscribe</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/resetpassword')}>Change Password</Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {isAuthorized && user?.role === "Admin" &&
                        <>
                        <Button variant="outlined"  className="mr-2"
                            onClick={() => history.push('/home/consumedCups')}
                        > Consumed Cups </Button>

                        <Button variant="outlined"  className="mr-2"
                         onClick={() => history.push('/home/consumedCupsPerBranch')}
                        >
                            Consumed Cups Per Branch
                        </Button>
                        <Button variant="outlined"  className="mr-2"
                        onClick={() => history.push('/home/redeemption')}
                        >
                            Redemption
                           
                        </Button>
                        <Button variant="outlined"  className="mr-2"
                        onClick={() => history.push('/home/subscription')}
                        
                        >
                            subscription
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu
                             style={{
                                backgroundColor:"#fffcf1"

                            }}
                            >
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/account')}>Create Barista Account </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/create-branch')}>Create Branch Account</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms &amp; Conditions</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/unsubscribeForClient')}>Unsubscribe For Client</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/disableClientCupRedemption')}>Disable client to redeem</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/enableClientCupRedemption')}>Enable client to redeem</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/resetClientPassword')}>Reset User Password</Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </>
                    }
                       {isAuthorized && user?.role === "SuperAdmin" &&

                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="ml-1 edit-account re-font">
                                My Account
                                </Dropdown.Toggle>

                            <Dropdown.Menu
                             style={{
                                backgroundColor:"#fffcf1"

                            }}
                            >
                                <Dropdown.Item onClick={() => history.push('/home')}>Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/adminDash')}>Admin Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/create')}>Create  Branch Account </Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/barista')}>Barista Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/terms')}>Terms</Dropdown.Item>
                                <Dropdown.Item onClick={() => history.push('/home/setting')}>Setting</Dropdown.Item>
                                <Dropdown.Item onClick={logoutHandler} >Log out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>

            </div>
        </nav>
    )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...authActions ,...paymentActions}, dispatch)
export default connect(null, mapDispatchToProps)(Header)
