import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';

function Datepicker({ label, callback, ...props }) {
    const [startDate, setStartDate] = useState(null);
    const handleDateChange = (date) => {
        setStartDate(date);
        callback && callback(date);
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={label}
            value={startDate}
            onChange={handleDateChange}       
            autoOk={true}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            {...props}
        />
        </MuiPickersUtilsProvider>
    )
}

export default Datepicker
