export default function (/**@type {ApisauceInstance} */ api) {
  const redeemCup = (payload) => api.post("Payment/redeem", payload);
  const subscribeClient = (payload) => api.post("Payment/subscribe", payload);
  const unSubscribeClient = (payload) =>
    api.post("Payment/unsubscribe", payload);
  const unSubscribeForClient = (payload) =>
    api.post("Payment/unsubscribe-for-client", payload);
  const disableClientCupRedemption = (payload) =>
    api.post("Payment/disable-client-cup-redemption", payload);
  const enableClientCupRedemption = (payload) =>
    api.post("Payment/enable-client-cup-redemption", payload);
  const paymentProcessed = () => api.post("Payment/payment-processed", null);
  const valuPaymentProcessed = () =>
    api.post("Payment/valu-payment-processed", null);

  return {
    payment: {
      redeemCup,
      subscribeClient,
      unSubscribeForClient,
      disableClientCupRedemption,
      enableClientCupRedemption,
      paymentProcessed,
      unSubscribeClient,
      valuPaymentProcessed,
    },
  };
}
