export default function (/**@type {ApisauceInstance} */ api) {
  const getBranchSubscription = () => api.get("Report/branch-subscription");
  const getTransactionsClients = (filter) => api.get("Report/transaction-clients",filter);
  const getAllBranches = () => api.get("Report/branches");
  const getTransactionsPerBranch = (filter) => api.get("Report/branch-transactions",filter);
  const getConsumedCups = (filter) => api.get("Report/consumed-cups",filter);
  const getTotalConsumedCups = (filter) => api.get("Report/consumed-cups",filter);
  const getConsumedCupsPagination = (filter) => api.get("Report/consumed-cups-pagination",filter);
  const getSubscriptionUsers = (filter) => api.get("Report/subscription-users",filter);
  const getTotalSubscriptionUsers = (filter) => api.get("Report/subscription-users",filter);

  const getConsumedCupsPerBranch = (filter) => api.get("Report/consumed-cups-branch",filter);
  const getTotalConsumedCupsPerBranch = (filter) => api.get("Report/consumed-cups-branch",filter);
  const getUserRedeemption = (filter) => api.get("Report/user-redeemption",filter);
  const getTotalUserRedeemption = (filter) => api.get("Report/user-redeemption",filter);
  const getClientCupTransactions = (filter) => api.get("Report/client-cup-transactions",filter);
  
  
  
    return {
      report: {
        getBranchSubscription,
        getTransactionsClients,
        getAllBranches,
        getTransactionsPerBranch,
        getConsumedCups,
        getConsumedCupsPerBranch,
        getUserRedeemption,
        getSubscriptionUsers,
        getConsumedCupsPagination,
        getTotalSubscriptionUsers,
        getTotalUserRedeemption,
        getTotalConsumedCupsPerBranch,
        getTotalConsumedCups,
        getClientCupTransactions,

      }
    };
  }
  