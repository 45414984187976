import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function LoadingButton({ loading = false, ...props }) {
    return (
        <>
            <Button
                disabled={loading}
                {...props}
            >              
                { props.children} {" "} {loading && <Spinner className='ml-1' animation="grow" size={'sm'} />}
            </Button>
        </>
    )
}

export default LoadingButton
